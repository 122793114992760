export default function () {
  return {
    theme: {
      active_color: '#f7941e',
      main_color: '#2b2b2c',
      secondary_color: '#535353',
      default_text_color: '#ffffff',
      secondary_text_color: '#d1d1d1',
      logo_path: 'https://api.skillgames.beta.spinnov.com/storage/img/profile_image.png',
      shield_path:
        'https://smartgoalstraining.com/skillgames-api/online-img/shield-bg.png'
    }
  }
}
